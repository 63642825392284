import { isRemoveTranslationsFallbackEnabled } from 'feature-flags';
import { fetchQuery, graphql } from 'relay-runtime';
import type { HCBrandingPortalTranslationResponse, HCBrandingResponse } from 'rest/help-center-branding';
import FeatureGates from '@atlaskit/feature-gate-js-client';
import { colors as themeColors } from '@atlaskit/theme';
import { isCSMHelpCenter } from '@atlassian/help-center-common-util/advanced-help-center';
import { getEnv } from '@atlassian/help-center-common-util/env';
import { getCloudId, getHelpCenterAri } from '@atlassian/help-center-common-util/meta';
import environment from '@atlassian/help-center-common-util/relay/environment';
import type { HelpCenterTranslation } from '../types';
import type {
    helpCenterBrandingQuery,
    helpCenterBrandingQuery$data,
} from './__generated__/helpCenterBrandingQuery.graphql';

export const fetchBrandingData = () => {
    return fetchQuery<helpCenterBrandingQuery>(
        environment,
        graphql`
            query helpCenterBrandingQuery($cloudId: ID!, $helpCenterAri: ID!) {
                helpCenter(cloudId: $cloudId) {
                    helpCenterById(helpCenterAri: $helpCenterAri) @optIn(to: ["HelpCenterAggBeta"]) {
                        ... on HelpCenter {
                            __typename
                            name {
                                default
                                translations {
                                    locale
                                    localeDisplayName
                                    value
                                }
                            }
                            helpCenterBranding @required(action: THROW) @optIn(to: ["HelpCenterBrandingTest"]) {
                                homePageTitle {
                                    default
                                    translations {
                                        locale
                                        localeDisplayName
                                        value
                                    }
                                }
                                logo {
                                    fileId
                                    url
                                }
                                banner {
                                    fileId
                                    url
                                }
                                colors {
                                    primary
                                    bannerTextColor
                                    topBarColor
                                    topBarTextColor
                                }
                                isBannerAvailable
                                isLogoAvailable
                                useDefaultBanner
                                hasTopBarBeenSplit
                            }
                            announcements {
                                canEditHomePageAnnouncement
                                homePageAnnouncements {
                                    name
                                    description
                                    nameTranslationsRaw {
                                        locale
                                        localeDisplayName
                                        value
                                    }
                                    descriptionTranslationsRaw {
                                        locale
                                        localeDisplayName
                                        value
                                    }
                                }
                            }
                            siteDefaultLanguageTag
                            userLanguageTag
                        }
                        ... on QueryError {
                            __typename
                        }
                    }
                }
            }
        `,
        {
            cloudId: getCloudId(),
            helpCenterAri: getHelpCenterAri(),
        },
        {
            fetchPolicy: 'store-or-network',
        }
    );
};

interface TranslationsAccumulator {
    [key: string]: {
        sharedPortalName?: string;
        helpCenterTitle?: string;
        announcementHeader?: string;
        announcementMessage?: string;
        localeDisplayName?: string;
    };
}

const mapTranslations = (
    translations: readonly (HelpCenterTranslation | undefined | null)[] | undefined | null,
    fieldName: keyof HCBrandingPortalTranslationResponse,
    acc: TranslationsAccumulator
) => {
    translations?.forEach((translation) => {
        if (translation && acc[translation?.locale] !== undefined) {
            acc[translation.locale][fieldName] = translation.value;
        } else if (translation) {
            {
                acc[translation?.locale] = {
                    [fieldName]: translation.value,
                    localeDisplayName: translation.localeDisplayName,
                };
            }
        }
    });
};

export const convertToHCBrandingResponse = (
    response: helpCenterBrandingQuery$data | undefined
): HCBrandingResponse | null => {
    const isCSM = isCSMHelpCenter(getEnv().helpCenterType);
    if (response === undefined) {
        return null;
    }
    const data = response?.helpCenter?.helpCenterById;
    if (data?.__typename === 'QueryError') {
        return null;
    }
    if (data?.__typename === 'HelpCenter') {
        const branding = data.helpCenterBranding;
        const announcements = data.announcements;
        const sharedPortalName = data.name;
        const { homePageAnnouncements } = announcements || {};
        const { logo, banner, homePageTitle, colors, hasTopBarBeenSplit } = branding;
        const initialTranslation: { [key: string]: HCBrandingPortalTranslationResponse } = {};
        if (!isRemoveTranslationsFallbackEnabled()) {
            initialTranslation['en-US'] = {
                helpCenterTitle: 'Welcome to the Help Center',
                sharedPortalName: 'Help Center',
                localeDisplayName: 'English (United States)',
                announcementHeader: '',
                announcementMessage: '',
            };
        }
        let translations: { [key: string]: HCBrandingPortalTranslationResponse } = {};
        if (homePageAnnouncements) {
            translations = homePageAnnouncements
                ?.filter((announcement) => !!announcement)
                .reduce((acc, announcement) => {
                    const { nameTranslationsRaw, descriptionTranslationsRaw } = announcement;
                    mapTranslations(nameTranslationsRaw, 'announcementHeader', acc);
                    mapTranslations(descriptionTranslationsRaw, 'announcementMessage', acc);
                    return acc;
                }, initialTranslation);
        }

        mapTranslations(sharedPortalName?.translations, 'sharedPortalName', translations);

        mapTranslations(homePageTitle?.translations, 'helpCenterTitle', translations);

        const getDefaultTitle = () => {
            if (isCSM) {
                return 'How can we help?';
            }
            return 'Welcome to the Help Center';
        };
        const isDefaultNavColorAsBannerColorEnabled = FeatureGates.checkGate(
            'default_navigation_color_as_banner_color'
        );
        const portalThemeColor = colors?.primary || '#0074e0';
        const helpCenterTitleColor = colors?.bannerTextColor || '#ffffff';
        return {
            translations,
            portalThemeColor,
            helpCenterTitleColor,
            logoUrl: logo?.url || '',
            logoId: logo?.fileId || '',
            bannerMediaApiFileId: banner?.fileId || '',
            bannerMediaApiUrl: banner?.url || '',
            helpCenterTitle: homePageTitle?.default || getDefaultTitle(),
            canEditAnnouncement: !!announcements?.canEditHomePageAnnouncement,
            userInitialAnnouncementHeader: (homePageAnnouncements && homePageAnnouncements[0]?.name) || '',
            userInitialAnnouncementMessageWiki: (homePageAnnouncements && homePageAnnouncements[0]?.description) || '',
            sharedPortalName: isCSM ? '' : sharedPortalName?.default || '',
            siteDefaultLanguageTag: data.siteDefaultLanguageTag || 'en-US',
            userLanguageTag: data.userLanguageTag || 'en-US',
            topBarColor:
                colors?.topBarColor || (isDefaultNavColorAsBannerColorEnabled ? portalThemeColor : themeColors.N0), // elevation.surface
            topBarTextColor:
                colors?.topBarTextColor ||
                (isDefaultNavColorAsBannerColorEnabled ? helpCenterTitleColor : themeColors.N800), // color.text
            hasTopBarBeenSplit: hasTopBarBeenSplit ?? false,
            isBannerAvailable: !!branding.isBannerAvailable,
            useDefaultBanner: !!branding.useDefaultBanner,
            isLogoAvailable: !!branding.isLogoAvailable,
        };
    }
    return null;
};
