import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import { di } from 'react-magnetic-di';
import type { ReqGroupsState, ReqTypesState } from 'state/persisted/portal';
import type { RequestTypeSuggestion } from 'state/persisted/request-type-suggestion';
import { ExternalLinks } from 'view/external-links';
import { HelpCenterContainer } from 'view/help-center/container';
import { RecentForms } from 'view/help-center/recent-forms';
import { useIsPageInEditMode } from 'view/layout-next/controllers';
import { token } from '@atlaskit/tokens';
import type { CategoryListProps } from '@atlassian/help-center-common-component/category-list';
import type { FormPickerProps } from '@atlassian/help-center-common-component/form-picker';
import { removeHyperlinks } from '@atlassian/help-center-common-util/parse';

interface SinglePortalProps {
    portalId: number;
    projectId: number | undefined;
    requestGroups: ReqGroupsState[];
    requestTypes: ReqTypesState[];
    requestTypeSuggestion: RequestTypeSuggestion[];
    FormPicker: React.ComponentType<FormPickerProps>;
    CategoryList: React.ComponentType<CategoryListProps>;
    description: string;
    knowledgeBaseHasCategories?: boolean;
    isEmbeddedRoute?: boolean;
}

const SinglePortal: React.FunctionComponent<SinglePortalProps> = ({
    FormPicker,
    CategoryList,
    portalId,
    projectId,
    requestTypeSuggestion,
    description,
    knowledgeBaseHasCategories,
    isEmbeddedRoute,
}) => {
    di(useIsPageInEditMode);
    const [isPageInEditMode] = useIsPageInEditMode();
    let descriptionToRender = description;
    if (isPageInEditMode && !!description) {
        descriptionToRender = removeHyperlinks(description);
    }
    return (
        <Root>
            {!!description && <Description dangerouslySetInnerHTML={{ __html: descriptionToRender }} />}

            {knowledgeBaseHasCategories && (
                <ContentContainer>
                    {/* Suppressing existing violation. Please fix this. */}
                    {/* eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage */}
                    <CategoryList cardLinkAppearance="white" portalId={portalId} isEmbeddedRoute={isEmbeddedRoute} />
                </ContentContainer>
            )}

            {!knowledgeBaseHasCategories && (
                <ContentContainer>
                    {/* Suppressing existing violation. Please fix this. */}
                    {/* eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage */}
                    <FormPicker cardLinkAppearance="white" portalId={portalId} isEmbeddedRoute={isEmbeddedRoute} />
                </ContentContainer>
            )}

            {requestTypeSuggestion.length > 0 && (
                <RecentForms suggestedRequestTypes={requestTypeSuggestion} isEmbeddedRoute={isEmbeddedRoute} />
            )}

            {projectId && (
                <ExternalLinksListWrapper>
                    <ExternalLinks projectId={projectId} />
                </ExternalLinksListWrapper>
            )}
        </Root>
    );
};

export default SinglePortal;

const BOTTOM_MARGIN_PX = token('space.600', '48px');

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Root = styled(HelpCenterContainer)`
    margin-top: ${token('space.400', '32px')};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Description = styled.div`
    margin: 0 0 ${token('space.400', '32px')};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const ContentContainer = styled.div`
    margin-bottom: ${BOTTOM_MARGIN_PX};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const ExternalLinksListWrapper = styled.div`
    margin-top: ${token('space.400', '32px')};
    margin-bottom: ${token('space.400', '32px')};
`;
