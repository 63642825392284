import { defineMessages } from 'react-intl-next';

export default defineMessages({
    requests: {
        defaultMessage: 'Requests',
        description: 'Requests item in menu',
        id: 'avatar.menu.v2.header.requests',
    },
    requestListHeading: {
        defaultMessage: 'REQUESTS LIST',
        description: 'Requests list header in menu request subsection',
        id: 'avatar.menu.v2.header.requests-list',
    },
    customizeRequestList: {
        defaultMessage: 'Manage columns',
        description: 'Manage columns item in menu request subsection',
        id: 'avatar.menu.v2.header.manage-column',
    },
    approvals: {
        defaultMessage: 'Approvals',
        description: 'Approvals item in menu',
        id: 'avatar.menu.v2.header.approvals',
    },
    profile: {
        defaultMessage: 'Profile',
        description: 'Profile item in menu',
        id: 'avatar.menu.v2.header.profile',
    },
    logout: {
        defaultMessage: 'Log out',
        description: 'Log out button in menu',
        id: 'avatar.menu.v2.logout',
    },
    pageLayout: {
        defaultMessage: 'Page layout',
        description: 'Topics and Portals in avatar drop-down menu',
        id: 'avatar.menu.v2.topic',
    },
    beta: {
        defaultMessage: 'Beta',
        description: 'Beta lozenge for flexi HC in avatar drop-down menu',
        id: 'avatar.menu.v2.label.beta',
    },
    newLozenge: {
        defaultMessage: 'New',
        description: 'BeNewta lozenge in avatar drop-down menu',
        id: 'avatar.menu.v2.label.newLozenge',
    },
    topicsAndPortals: {
        defaultMessage: 'Topics and portals',
        description: 'Topics and Portals in avatar drop-down menu when advanced customization enabled',
        id: 'avatar.menu.v2.topics.and.portals',
    },
    look: {
        defaultMessage: 'Look and feel',
        description: 'look and feel item in menu',
        id: 'avatar.menu.v2.look',
    },
    announcement: {
        defaultMessage: 'Announcements',
        description: 'announcement item in menu',
        id: 'avatar.menu.v2.announcement',
    },
    signIn: {
        defaultMessage: 'Log in message',
        description: 'sign in message item in menu',
        id: 'avatar.menu.v2.signIn',
    },
    helpCenterSettings: {
        defaultMessage: 'Service hub settings',
        description: 'Service hub settings navigation item',
        id: 'avatar.menu.v2.helpCenterSettings',
    },
    customerHubSettings: {
        defaultMessage: 'Customer hub settings',
        description: 'Customer hub settings navigation item',
        id: 'avatar.menu.v2.customerHubSettings',
    },
    tooltip: {
        defaultMessage: 'Profile and settings',
        description: 'tooltip on avatar',
        id: 'avatar.profile.v2.tooltip',
    },
    reporting: {
        defaultMessage: 'Performance',
        description: 'Navigates to Reporting page',
        id: 'avatar.menu.v2.reporting',
    },
    virtualServiceAgent: {
        defaultMessage: 'Virtual service agent',
        description: 'Navigates to virtual service agent page',
        id: 'avatar.menu.v2.virtualServiceAgent',
    },
    portalAnnouncement: {
        defaultMessage: 'Announcements',
        description: 'announcement item in menu portal subsection',
        id: 'avatar.menu.v2.portalAnnouncement',
    },
    portalRequestTypeGroup: {
        defaultMessage: 'Portal groups',
        description: 'portal groups item in menu portal subsection',
        id: 'avatar.menu.v2.requestTypeGroup',
    },
    portal: {
        defaultMessage: 'PORTAL',
        description: 'Portal heading for menu portal subsection',
        id: 'avatar.menu.v2.portal.section',
    },
    helpCenter: {
        defaultMessage: 'HELP CENTER',
        description: 'Help Center heading for menu multi hc subsection',
        id: 'avatar.menu.v2.helpCenter.section',
    },
    onboardingDescriptionCreateOrHcClickWithLink: {
        defaultMessage:
            'Upgrade to Jira Service Management Premium to create and manage multiple help centers on your site and ',
        description: 'Upgrade modal description a help center is clicked or create a help center is clicked',
        id: 'uh.home.downgrade.modal.description.create.or.hc.with.link',
    },
    // eslint-disable-next-line rulesdir/i18n-unique-message-ids
    onboardingTitleHCClick: {
        defaultMessage: 'Serve different audiences with multiple help centers',
        description: 'Upgrade modal title when help center is clicked',
        id: 'uh.home.downgrade.modal.title.hc',
    },
    freeStandardAdminsChangeBoardingHeading: {
        defaultMessage: 'Your help center settings have moved here',
        description: 'Heading text for Free Standard Admins Change Boarding',
        id: 'sd.topic.filter.free.and.standard.changeboarding.heading',
    },
    freeStandardAdminsChangeBoardingContent: {
        defaultMessage: 'Manage profile settings, view your requests, and customize your help center from here.',
        description: 'Content for topics Free Standard Admins Change Boarding',
        id: 'sd.topic.filter.free.and.standard.changeboarding.content',
    },
    freeStandardAdminsChangeBoardingButton: {
        defaultMessage: 'OK',
        description: 'Button text for Free Standard Admins Change Boarding',
        id: 'sd.topic.filter.free.and.standard.changeboarding.button',
    },
    singlePortalScreenAdminsChangeBoardingHeading: {
        defaultMessage: 'Your portal settings have moved here',
        description: 'Heading text for Single Portal Screen Admins Change Boarding',
        id: 'sd.topic.filter.single.portal.screen.admins.changeboarding.heading.',
    },
    singlePortalScreenAdminsChangeBoardingContent: {
        defaultMessage: 'Access your requests, profile settings, and customize the portal experience from here',
        description: 'Content for topics Single Portal Screen Admins Change Boarding',
        id: 'sd.topic.filter.single.portal.screen.admins.changeboarding.content',
    },
    singlePortalScreenAdminsChangeBoardingButton: {
        defaultMessage: 'OK',
        description: 'Button text for Single Portal Screen Admins Change Boarding',
        id: 'sd.topic.filter.single.portal.screen.admins.changeboarding.button',
    },
    manageHc: {
        defaultMessage: 'View all help centers',
        description: 'Message for navigating to hc-management screen in avatar menu',
        id: 'avatar.menu.v2.manageHc',
    },
    managePages: {
        defaultMessage: 'Landing pages',
        description: 'Message for navigating to landing pages screen in avatar menu',
        id: 'avatar.menu.v2.help.center.pages',
    },
    createHc: {
        defaultMessage: 'Create a help center',
        description:
            'Label for create help center button in avatar menu which is used to navigate to the help center management page',
        id: 'avatar.menu.v2.createHc',
    },
    pageLayoutPremiumDescription: {
        defaultMessage: 'You need to be on a Premium or Enterprise plan to edit sections in your home page layout.',
        description: 'Description for premium suggestion of feature Page Layout',
        id: 'avatar.menu.v2.pageLayout.premium.description',
    },
    pageLayoutTryButton: {
        defaultMessage: 'Try it now',
        description: 'Button to try the premium feature Page Layout',
        id: 'avatar.menu.v2.pageLayout.premium.try.button',
    },
});
