import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import jsdIcon from 'assets/images/jsd-icon.svg';
import { isCsmContactUsSectionEnabled, isEditLayoutEnabledForCSM } from 'feature-flags';
import { FormattedMessage } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import { usePathParam, useRouter } from 'react-resource-router';
import { ContactUsSection as CsmContactUsSection } from 'view/customer-service/contact-us-section';
import * as fonts from 'view/styles/fonts';
import * as transitions from 'view/styles/transitions';
import { AnalyticsContext } from '@atlaskit/analytics-next';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { HtmlAnchor } from '@atlassian/help-center-common-component/analytics/html-anchor';
import { CTAContainer } from '@atlassian/help-center-common-component/cta-container';
import { isCSMHelpCenter } from '@atlassian/help-center-common-util/advanced-help-center';
import { getEnv } from '@atlassian/help-center-common-util/env';
import { shouldShowCTA } from './internal/utils';
import i18n from './messages';
import { footerPadding, footerJsdIconHeight } from './styled';

interface Props {
    linkTo: string;
    className?: string;
    isCSM?: boolean;
}

const EDIT_HOMEPAGE_ROUTE_NAME = 'edit-homepage';

const Footer = ({ linkTo, className, isCSM }: Props) => {
    di(useRouter, usePathParam);
    const [{ route }] = useRouter();
    const [requestTypeId] = usePathParam('requestTypeId');

    // isCSM check is used for generating correct footer in CSMRequest Type Previews
    const showCSMFooter = isCSMHelpCenter(getEnv().helpCenterType) || isCSM;

    const renderCsmFooter = () => {
        if (isEditLayoutEnabledForCSM()) {
            // TODO: move contact us section to edit csm page, once page builder allows additional children components
            return isCsmContactUsSectionEnabled() && route.name === EDIT_HOMEPAGE_ROUTE_NAME ? (
                <CsmContactUsSection />
            ) : null;
        }

        return (
            <CsmFooter>
                <FormattedMessage {...i18n.csmFooter} />
            </CsmFooter>
        );
    };

    return (
        <AnalyticsContext data={{ source: 'footer' }}>
            {shouldShowCTA(route.name, requestTypeId) && <CTAContainer />}
            {/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve */}
            <FooterContainer id="portal-layout-footer" className={className}>
                {showCSMFooter ? (
                    renderCsmFooter()
                ) : (
                    <FooterLink
                        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                        className="cv-exclude-from-customisation"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={linkTo}
                        actionSubjectId="waclink"
                    >
                        <FormattedMessage
                            {...i18n.footer}
                            values={{
                                iconHtml: <FooterJsdIcon src={jsdIcon} alt="" />,
                            }}
                        />
                    </FooterLink>
                )}
            </FooterContainer>
        </AnalyticsContext>
    );
};

export default Footer;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const FooterContainer = styled.footer`
    padding: ${token('space.600', `${footerPadding}px`)} 0;
    text-align: center;
    white-space: nowrap;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const FooterJsdIcon = styled.img`
    object-fit: contain;
    height: ${token('space.250', `${footerJsdIconHeight}px`)};
    vertical-align: middle;
    opacity: 0.5;
    transition: opacity ${transitions.timingFunctions.easeInOut} ${transitions.speedMs.fast}ms;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const FooterLink = styled(HtmlAnchor)`
    ${fonts.small};
    color: ${token('color.text.subtlest', colors.N300)};
    cursor: pointer;

    :hover,
    :active {
        color: ${token('color.text.subtlest', colors.N300)};
        text-decoration: none;

        ${FooterJsdIcon} {
            opacity: 1;

            stop:first-child {
                stop-color: ${token('color.background.brand.bold.hovered', colors.B400)};
            }

            stop:last-child {
                stop-color: ${token('color.background.brand.bold', colors.B200)};
            }

            > g > g > path:last-child {
                fill: ${token('color.background.brand.bold', colors.B200)};
            }
        }
    }
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const CsmFooter = styled.div`
    ${fonts.small};
    color: ${token('color.text.subtlest', colors.N300)};
`;
