import React from 'react';
import { styled } from '@compiled/react';
import { isIncreasedFlexibilityEnabled } from 'feature-flags';
import { LoadingSkeleton } from 'view/common/loading';
import { HEADER_HEIGHT } from 'view/common/styled';
import { Header as DefaultHeader } from 'view/layout/header';
import { PrefetchBrandingModel } from 'view/layout/prefetch-branding-model';
import * as grid from 'view/styles/grid';
import FeatureGates from '@atlaskit/feature-gate-js-client';
import { Box, xcss } from '@atlaskit/primitives';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export const BANNER_HEIGHT = grid.unitless * 23; // 184
export const BANNER_HEIGHT_WITH_HEADER = grid.unitless * 23 + HEADER_HEIGHT; // 240
export const BANNER_HEIGHT_PAPER_LAYOUT = grid.unitless * 37; // 296
export const BANNER_HEIGHT_INCREASED_FLEXIBILITY = grid.unitless * 22; // 176

const BANNER_WITH_REDUCED_HEIGHT = 152;

export const BANNER_IDENTIFIER = 'layout-banner';

export interface Props {
    themeColor: string;
    isAnonymous?: boolean;
    removeHeader?: boolean;
    headerless?: boolean;
    hideSearchButton?: boolean;
    helpCenterBanner: string | undefined;
    isBrandingLoaded?: boolean;
    hideBanner?: boolean;
    prefetchBrandingModel?: boolean;
    hasTopBarBeenSplit?: boolean;
    renderForPaperLayout?: boolean;
}

export const BannerWithLayoutHeader = ({
    themeColor,
    isAnonymous,
    removeHeader,
    headerless,
    hideSearchButton,
    helpCenterBanner,
    isBrandingLoaded,
    hideBanner,
    prefetchBrandingModel,
    hasTopBarBeenSplit,
    renderForPaperLayout,
}: Props) => {
    const isVisualRefreshReducedSizeEnabled = FeatureGates.checkGate('visual_refresh_reduced_banner_height');

    let updatedBannerHeight = hasTopBarBeenSplit ? BANNER_HEIGHT : BANNER_HEIGHT_WITH_HEADER;
    if (isVisualRefreshReducedSizeEnabled) {
        updatedBannerHeight = hasTopBarBeenSplit ? BANNER_HEIGHT : BANNER_WITH_REDUCED_HEIGHT;
    }
    if (renderForPaperLayout) {
        updatedBannerHeight = hasTopBarBeenSplit
            ? BANNER_HEIGHT_PAPER_LAYOUT
            : BANNER_HEIGHT_PAPER_LAYOUT + HEADER_HEIGHT; // in login page we render paper layout
    }
    if (isIncreasedFlexibilityEnabled() && hasTopBarBeenSplit && !renderForPaperLayout) {
        updatedBannerHeight = BANNER_HEIGHT_INCREASED_FLEXIBILITY;
        if (isVisualRefreshReducedSizeEnabled) {
            updatedBannerHeight = BANNER_WITH_REDUCED_HEIGHT;
        }
    }
    const bannerCustomProp = {
        style: {
            height: `${updatedBannerHeight}px`,
            backgroundColor: themeColor,
            backgroundImage: helpCenterBanner ? `url(${helpCenterBanner})` : 'none',
        },
    };
    const bannerContainerCustomProp = {
        style: {
            height: `${updatedBannerHeight}px`,
            top: renderForPaperLayout && hasTopBarBeenSplit ? `${HEADER_HEIGHT}px` : 'initial',
            bottom: renderForPaperLayout ? 'initial' : 0,
        },
    };
    const showBannerWithLoader = () => {
        if (hideBanner) return null;
        if (isIncreasedFlexibilityEnabled() && hasTopBarBeenSplit && !renderForPaperLayout) {
            return (
                <Box
                    xcss={
                        isVisualRefreshReducedSizeEnabled ? bannerContainerStyleWithReducedHeight : bannerContainerStyle
                    }
                >
                    {isBrandingLoaded ? (
                        <Box data-testId={BANNER_IDENTIFIER} xcss={bannerStyle} {...bannerCustomProp} />
                    ) : (
                        <UFOLoadHold name="banner-loader">
                            <LoadingSkeleton
                                data-testid="banner-skeleton"
                                height={updatedBannerHeight}
                                backgroundColor={token('color.skeleton.subtle', colors.N20)}
                            />
                        </UFOLoadHold>
                    )}
                </Box>
            );
        }

        return (
            <BannerContainer {...bannerContainerCustomProp}>
                {isBrandingLoaded ? (
                    <BannerStyle data-testId={BANNER_IDENTIFIER} {...bannerCustomProp} />
                ) : (
                    <UFOLoadHold name="banner-loader">
                        <LoadingSkeleton
                            data-testid="banner-skeleton"
                            height={updatedBannerHeight}
                            backgroundColor={token('color.skeleton.subtle', colors.N20)}
                        />
                    </UFOLoadHold>
                )}
            </BannerContainer>
        );
    };

    let containerHeight = renderForPaperLayout ? HEADER_HEIGHT : BANNER_HEIGHT_WITH_HEADER;
    if (isVisualRefreshReducedSizeEnabled) {
        containerHeight = renderForPaperLayout ? HEADER_HEIGHT : BANNER_WITH_REDUCED_HEIGHT;
    }
    if (isIncreasedFlexibilityEnabled() && hasTopBarBeenSplit && !renderForPaperLayout) {
        containerHeight = HEADER_HEIGHT + BANNER_HEIGHT_INCREASED_FLEXIBILITY + 16 * 2;
        if (isVisualRefreshReducedSizeEnabled) {
            containerHeight = HEADER_HEIGHT + BANNER_WITH_REDUCED_HEIGHT + 16 * 2;
        }
    }

    return (
        <Container
            style={{
                height: removeHeader || headerless ? 'auto' : `${containerHeight}px`,
            }}
        >
            {showBannerWithLoader()}
            {prefetchBrandingModel ? <PrefetchBrandingModel isAnonymous={isAnonymous} /> : null}
            {!removeHeader && !headerless && (
                <HeaderContainerStyle>
                    <DefaultHeader
                        isAnonymous={isAnonymous}
                        logoTextColor="banner"
                        hideSearchButton={hideSearchButton}
                        showThemedBackground={false}
                        isModelCallPrefetched={prefetchBrandingModel}
                    />
                </HeaderContainerStyle>
            )}
            {!removeHeader && headerless && (
                <EmptyHeader
                    style={{
                        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
                        height: `${HEADER_HEIGHT}px`,
                    }}
                />
            )}
        </Container>
    );
};

export const BannerWithLayoutHeaderMemoized = React.memo(BannerWithLayoutHeader);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Container = styled.div({
    position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const BannerStyle = styled.div({
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
    backgroundColor: token('color.background.brand.bold', colors.B200),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const BannerContainer = styled.div({
    position: 'absolute',
    width: '100%',
});

const bannerContainerStyle = xcss({
    position: 'absolute',
    width: '100%',
    paddingTop: 'space.200',
    paddingBottom: 'space.200',
    paddingLeft: 'space.400',
    paddingRight: 'space.400',
    borderRadius: 'border.radius.200',
    overflow: 'hidden',
    bottom: 'space.0',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
    height: `${BANNER_HEIGHT_INCREASED_FLEXIBILITY + 16 * 2}px`,
});

const bannerContainerStyleWithReducedHeight = xcss({
    position: 'absolute',
    width: '100%',
    paddingTop: 'space.200',
    paddingBottom: 'space.200',
    paddingLeft: 'space.400',
    paddingRight: 'space.400',
    borderRadius: 'border.radius.200',
    overflow: 'hidden',
    bottom: 'space.0',

    height: `${BANNER_WITH_REDUCED_HEIGHT + 16 * 2}px`,
});

const bannerStyle = xcss({
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    borderRadius: 'border.radius.200',
    backgroundColor: 'color.background.brand.bold',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const HeaderContainerStyle = styled.div({
    position: 'relative',
    zIndex: 'navigation',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const EmptyHeader = styled.div({
    width: '100%',
});
